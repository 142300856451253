import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function VoiceType({ value, onChange }) {
  return (
    <FormControl>
      <FormLabel id="voice-type-group-label" sx={{ fontSize: "0.9em", textAlign: "left" }}>
        Pronunciation
      </FormLabel>
      <RadioGroup
        aria-labelledby="voice-type-group-label"
        name="voice-type-group"
        row
        value={value}
        onChange={onChange}>
        <FormControlLabel value="1" control={<Radio size="small" />} label="British" />
        <FormControlLabel
          value="0"
          control={<Radio size="small" />}
          label="American"
          sx={{ fontSize: "0.8em" }}
        />
      </RadioGroup>
    </FormControl>
  );
}
