import axios from "axios";

const server = {
  getWordGroups: async function () {
    const url = "https://www.veryselection.com/words/study";
    return await axios.get(url);
  },
};

export default server;
