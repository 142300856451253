import logo from "./logo.svg";
import "./App.css";
import * as React from "react";
import { useState } from "react";
import { useRef } from "react";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CustomTabPanel from "./CustomTabPanel";
import VoiceType from "./VoiceType";
import TextField from "@mui/material/TextField";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import server from "./server";

const GridItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // paddingLeft: theme.spacing(1),
  // padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function App() {
  const [words, setWords] = useState(["begin"]);
  const [wordGroups, setWordGroups] = useState([
    { groupid: 1, name: "words group1", words: ["hello", "world"] },
    { groupid: 2, name: "words group2", words: ["good", "well"] },
  ]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [inputWord, setInputWord] = useState("");
  const [inputTextArea, setInputTextArea] = useState("");
  const [delaySeconds, setDelaySeconds] = useState(3);
  const [videoUrl, setVideoUrl] = useState("");
  const [mask, setMask] = useState(false);
  const [repeatTimes, setRepeatTimes] = useState(3);
  const [voice, setVoice] = useState(1);
  // cancel dictation
  const cancel = useRef(false);

  const [tabIndex, setTabIndex] = useState(0);

  React.useEffect(() => {
    (async () => {
      let arr = await server.getWordGroups();
      // console.log(arr.data);
      setWordGroups(arr.data);
    })();
  }, []);

  const handleChangeTab = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  function changeVideoWord(word, voiceType) {
    //type: 0-us, 1-uk
    if (null !== word)
      setVideoUrl("http://dict.youdao.com/dictvoice?type=" + voiceType + "&audio=" + word);
    document.getElementById("myVideo").load();
  }

  function onSelectWord(word) {
    setSelectedWord(word);
    changeVideoWord(word, voice);
  }
  function onInput(e) {
    setInputWord(e.target.value);
  }
  function onAdd() {
    // console.log("input:" + inputWord);
    let newWord = inputWord.trim();
    if (newWord !== "") {
      if (words.includes(newWord)) {
        alert("Duplicated Word!");
        setInputWord("");
        return;
      }
      setWords(() => {
        return [...words, newWord];
      });
      changeVideoWord(newWord, voice);
      setInputWord("");
    }
    if (inputTextArea.trim() !== "") {
      let arrLine = inputTextArea.split("\n");
      let arrNew = words;
      arrLine.forEach((aLine) => {
        let arrWord = aLine.split(" ");
        arrWord.forEach((word) => {
          newWord = word.trim();
          if (newWord !== "") {
            if (!arrNew.includes(newWord)) {
              arrNew.push(newWord);
            }
          }
        });
      });
      setWords(arrNew);
      setInputTextArea("");
    }
  }

  function onRemove(delWord) {
    setWords(() => {
      return words.filter((element) => element !== delWord);
    });
    //setSelectedWord(null);
  }

  var repeatRead = 0;
  function readWord(index) {
    if (index >= words.length) {
      setMask(false);
      return;
    }
    if (cancel.current) {
      return;
    }
    var word = words[index];
    changeVideoWord(word, voice);
    repeatRead++;
    // console.log("i=" + index);
    // console.log("repeatRead=" + repeatRead);
    if (index < words.length) {
      if (repeatRead < repeatTimes) {
        setTimeout(() => {
          readWord(index);
        }, delaySeconds * 1000);
      } else {
        repeatRead = 0;
        setTimeout(() => {
          readWord(index + 1);
        }, delaySeconds * 1000);
      }
    }
  }

  function onDictation() {
    repeatRead = 0;
    cancel.current = false;
    setMask(true);
    readWord(0);
  }

  function onChangeSeconds(e) {
    let i = parseInt(e.target.value);
    if (i < 3) {
      i = 3;
    }
    setDelaySeconds(i);
  }

  function onChangeRepeatTimes(e) {
    let i = parseInt(e.target.value);
    if (i < 1) {
      i = 1;
    }
    setRepeatTimes(i);
  }

  function onChangeVoice(e) {
    let i = parseInt(e.target.value);
    if (i !== 0 && i !== 1) {
      i = 0;
    }
    // setVideoUrl(null);
    // setSelectedWord(null);
    setVoice(i);
    // console.log('i=' + i);
    if (null !== selectedWord) changeVideoWord(selectedWord, i);
  }

  function onInputTextArea(e) {
    let text = e.target.value;
    setInputTextArea(text);
  }

  function onClickCancel(e) {
    cancel.current = true;
    setMask(false);
  }

  const wordItems2 = (
    <Card variant="outlined" sx={{ marginBottom: 10 }}>
      <Table sx={{ minWidth: 100 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ p: 1 }}>Word</TableCell>
            <TableCell sx={{ p: 1 }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {words.map((word) => {
            return (
              <TableRow key={word} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ p: 1 }}
                  onClick={() => onSelectWord(word)}>
                  {word}
                </TableCell>
                <TableCell align="center" sx={{ p: 1 }}>
                  <IconButton sx={{ p: 0 }} onClick={() => onRemove(word)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );

  function changeGroup(groupid) {
    console.log("groupid=" + groupid);
    let group = wordGroups.filter((element) => element.groupid === groupid)[0];
    setWords(group.words);
  }

  const wordGroupsComponent = (
    <Box sx={{ marginBottom: 10 }}>
      {wordGroups.map((group) => {
        return (
          <Box key={"box" + group.groupid} sx={{ pt: 2 }}>
            <Button onClick={() => changeGroup(group.groupid)} variant="contained">
              {group.name}
            </Button>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        Study Words
      </header>
      <div className="voice-type"></div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="Study Words" centered>
            <Tab label="Words" {...a11yProps(0)} />
            <Tab label="Dictation" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabIndex} index={0}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} sx={{ justifyContent: "center" }}>
              <Grid xs={12} sm={6} md={4} lg={3}>
                <Box sx={{ p: 2 }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Input Words"
                    multiline
                    fullWidth
                    rows={4}
                    value={inputTextArea}
                    onChange={onInputTextArea}
                  />
                </Box>
                <Box>
                  <Button
                    onClick={onAdd}
                    variant="contained"
                    disabled={inputWord === "" && inputTextArea === ""}>
                    Add
                  </Button>
                </Box>
                {wordGroupsComponent}
              </Grid>
              <Grid xs={12} sm={6} md={4} lg={3}>
                <Box sx={{ pt: 2, pl: 1, pr: 1 }}>{wordItems2}</Box>
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <Box className="row-item">
            <VoiceType value={voice} onChange={onChangeVoice} />
          </Box>
          <Box className="row-item">
            <TextField
              label="Delay Seconds"
              type="number"
              variant="standard"
              value={delaySeconds}
              onChange={onChangeSeconds}
            />
          </Box>
          <Box className="row-item">
            <TextField
              label="Repeat Read Times"
              type="number"
              variant="standard"
              value={repeatTimes}
              onChange={onChangeRepeatTimes}
            />
          </Box>
          <Box className="row-item">
            <Button onClick={onDictation} disabled={words.length == 0} variant="contained">
              Start Dictation
            </Button>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box hidden>
        <video id="myVideo" controls autoPlay name="media">
          <source src={videoUrl} type="audio/mpeg" />
        </video>
      </Box>

      <div className="mask" style={{ display: mask ? "block" : "none" }}>
        <div className="mask-content">
          Dictation is running ...
          <div className="cancel-button">
            <Button onClick={onClickCancel} variant="outlined">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
